import React, { useState } from "react"
import firebase from "gatsby-plugin-firebase"
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';

import Select from "./search/select"
import Year from "./search/year";
import slugify from "slugify";


export default function Search({user}) {
  const [competitionsRaw, loading, error] = useDocumentDataOnce(typeof firebase.firestore === 'function' ? firebase.firestore().collection("filters").doc("filters") : null);

  const [indexes, setIndexes] = useState({selectName: 0, selectCategory: 0, selectRound: 0});

  const selectRoundChange = (value) => {
    setIndexes({...indexes, selectRound: value});
  }
  const selectCategoryChange = (value) => {
    setIndexes({...indexes, selectCategory: value, selectRound: 0});
  }
  const selectNameChange = (value) => {
    setIndexes({selectName: value, selectCategory: 0, selectRound: 0});
  }

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error || typeof competitionsRaw === "undefined") {
    console.log(loading);
    console.log(error);
    console.log(competitions);
    return (
      <div className="alert alert-danger d-flex" role="alert">
        <div className="me-auto">Nem tudtuk betölteni a feladatokat!</div>
      </div>
    );
  }

  const competitions = competitionsRaw.filters;
  let selectedCompetition = competitions[indexes.selectName];
  let selectedCategory = selectedCompetition.categories[indexes.selectCategory];
  let selectedRound = selectedCategory.rounds[indexes.selectRound];

  return (
    <>
      <div className="row gy-2">
        <Select id="selectName" onChange={selectNameChange} options={competitions} ></Select>
        <Select id="selectCategory" onChange={selectCategoryChange} options={selectedCompetition.categories}></Select>
        <Select id="selectRound" onChange={selectRoundChange} options={selectedCategory.rounds}></Select>
      </div>
      <div className="accordion my-4" id="accordionYears">
        {
          selectedRound.years ? selectedRound.years.map(year => (
            <Year key={slugify(year.title)} year={year} filters={{competition: selectedCompetition.title, category: selectedCategory.title, round: selectedRound.title, year: year.title}}></Year>
          )) : null
        }
        
      </div>
    </>
  );
}

