import React from "react"

const UrlButton = ({to, children}) => (
  <a className={`btn btn-outline-light btn-sm ${to === undefined ? 'disabled' : ''}`} target="_blank" rel="noreferrer" href={to} role="button">{children}</a>
)

export default function Task({card}){
  const typeColors = {
    "Grafika": "bg-warning",
    "Szövegszerkesztés": "bg-primary",
    "Táblázatkezelés": "bg-success",
    "Adatbáziskezelés": "bg-danger",
    "Webszerkesztés": "bg-info",
  }
  return (
    <div className="col-xl-3 col-lg-4 col-md-6">
      <div className={`card text-white ${typeColors[card.type]}`}>
        <div className="card-body">
          <h5 className="card-title">{card.number}</h5>
          <h6 className="card-subtitle mb-2">{card.type}</h6>
          <p className="card-text">{card.title}</p>
          <div className="d-grid gap-2">
            <UrlButton to={card.urls.task}>Feladat</UrlButton>
            <UrlButton to={card.urls.resource}>Forrás</UrlButton>
            <UrlButton to={card.urls.grading}>Pontozás</UrlButton>
            <UrlButton to={card.urls.solution}>Megoldás</UrlButton>
            <UrlButton to={card.urls.guide}>Útmutató</UrlButton>
          </div>
        </div>
      </div>
    </div>
  )
}