import React, { useState } from "react"
import Layout from "../components/layout"
import Search from "../components/search";

export default function Home() {
  const [user, setUser] = useState(typeof localStorage !== 'undefined' ? JSON.parse(localStorage.getItem('currentUser')) : null);
  return (
    <Layout setUser={setUser} user={user}>
      <Search user={user}/>
    </Layout>
  );
}
