import React from "react"

export default function Select({id, options, onChange}) {
  const handleChange = event => {
    onChange(event.target.selectedIndex);
  };

  return (
    <div className="col-lg">
      <select className="form-select" id={id} onChange={handleChange}>
        {
          options ? options.map(option => (
            <option key={option.title}>{option.title}</option>
          )) : null
        }
      </select>
    </div>
  )
}