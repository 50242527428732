import React, {useEffect, useRef, useState} from "react"
import slugify from "slugify"
import firebase from "gatsby-plugin-firebase"
import Task from "./task";

export default function Year({year, filters}) {
  const id = slugify(year.title);
  const collapseRef = useRef();
  const [tasks, setTasks] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  useEffect(() => {
    const loadYearData = () => {
      console.log(filters);
      firebase.firestore().collection("years")
      .where("competition", "==", filters.competition)
      .where("category", "==", filters.category)
      .where("round", "==", filters.round)
      .where("title", "==", filters.year)
      .get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setTasks(doc.data().tasks);
          setLoading(false);
        });
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
          console.log("Error while querying tasks for that year: ", error);
      });
    }

    if (collapseRef && collapseRef.current) {
      const collapseRefVar = collapseRef.current;
      collapseRefVar.addEventListener("show.bs.collapse", loadYearData, false);
      return () => {
        collapseRefVar.removeEventListener("show.bs.collapse", loadYearData, false);
      };
    }
  });

  const Tasks = () => {
    if (loading) {
      return (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      );
    }
  
    if (error) {
      return (
        <div className="alert alert-danger d-flex" role="alert">
          <div className="me-auto">Nem tudtuk betölteni a feladatokat!</div>
        </div>
      );
    }

    return  tasks ? tasks.map(task => (
      <Task key={slugify(task.title)} card={task}/>
    )) : null;

  }
  
  return (
    <div className="accordion-item">
      <h2 className="accordion-header">
        <button className="yearTitle accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#year'+id}>
          {year.title}
        </button>
      </h2>
      <div id={'year' + id} ref={collapseRef} className="accordion-collapse collapse" data-bs-parent="#accordionYears">
        <div className="accordion-body">
          <div className="row gy-3 cardRow">
            <Tasks />
          </div>
        </div>
      </div>
    </div>
  )
}